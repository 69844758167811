.App {
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .App-section {
    background-color: #d5e4fc;
    background-size: 100%;
    background-image: none;
    min-height: 20px;
  }
}
.App-section {
  background: no-repeat;
  background-image: url('/public/charlie.jpg');
  background-size: 100%;
  opacity: 90%;
  min-height: 100vh;
}
.App-subsection {
  background-color: #d5e4fc;
  min-height: 50vh;
}
.App-header {
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: 40px;
  padding-top: 100px;
  color: white;
}
.App-subheader {
  font-weight: 800;
  /* min-height: 100vh; */
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: white;
}
.contact-form-subheader {
  font-weight: 400;
  /* min-height: 100vh; */
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}
.App-subheader-2 {
  font-weight: 800;
  /* min-height: 100vh; */
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #4e7ec1;
  padding-inline: 10%;
}
.button {
  margin: 30px;
  font-weight: 800;
  background-color: #4e7ec1;
  border: none;
}
.button2 {
  margin: 30px;
  font-weight: 800;
  background-color: white;
  border: none;
  color: #4e7ec1;
}

.contact-form{
  /* background: rgba(255,255,255, 0.5); */
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-form-item {
  margin-bottom: 10px;
}
.contact-form-text {
  font-weight: 800;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  padding-inline: 10%;
}